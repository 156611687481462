<template>
  <ion-page>
    <ion-header class="custom-header">
      <ion-toolbar class="custom-toolbar" mode="ios">
        <ion-title class="fs-3 text-center">{{
          !isEdit ? $t('accountPage.add_card') : $t('accountPage.edit_card')
        }}</ion-title>
        <div class="text-end">
          <ion-icon
            slot="icon-only"
            size="small"
            :icon="closeOutline"
            @click="closeModal()"
            class="fix-icon pt-1"
          ></ion-icon>
        </div>
      </ion-toolbar>
    </ion-header>
    <ion-content scroll-y="false">
      <vee-form class="px-3" :validation-schema="cardSchema">
        <ion-grid class="fs-2">
          <!-- First row -->
          <ion-row>
            <ion-col>
              <ion-item
                :class="['fs-3 custom-item', 'item-' + getColorValidate('cardNumber', errors.cardNumber)]"
              >
                <ion-label :color="getColorValidate('cardNumber', errors.cardNumber)" position="stacked">
                  {{ $t('accountPage.card_number') }}
                </ion-label>
                <ion-input
                  v-model="card.cardNumber"
                  :value="card.cardNumber"
                  name="cardNumber"
                  :placeholder="`${
                    !isEdit ? `**** **** **** 9515` : `**** **** **** ` + `${infoCard.last4}`
                  }`"
                  type="tel"
                  maxlength="19"
                  @paste="onPaste"
                  @keypress="onCardNumberChange"
                  @ionChange="onCardNumberChange"
                  @ionInput="onCardNumberChange"
                >
                  <div class="box-image">
                    <img class="card-image" :src="cardImg(cardBrand)" />
                  </div>
                </ion-input>
              </ion-item>
              <div class="d-flex">
                <ion-text v-if="errors.cardNumber" color="danger">
                  <div class="pr-1 py-1">{{ $t(`${errors.cardNumber}`) }}</div>
                </ion-text>
              </div>
            </ion-col>
          </ion-row>
          <!-- Second row  -->
          <ion-row class="mt-3">
            <ion-col class="pl-0">
              <ion-item
                :class="[
                  'fs-3 custom-item',
                  errors.expMonth || !isValidMonth
                    ? 'item-danger'
                    : card.expMonth.length > 0
                    ? 'item-primary'
                    : ''
                ]"
              >
                <ion-label
                  :color="
                    errors.expMonth || !isValidMonth ? 'danger' : card.expMonth.length > 0 ? 'primary' : ''
                  "
                  position="stacked"
                >
                  {{ $t('accountPage.expiry_date') }}
                </ion-label>
                <ion-input
                  v-model="card.expMonth"
                  :value="card.expMonth"
                  name="expMonth"
                  :placeholder="
                    !isEdit
                      ? 'MM/YY'
                      : `${infoCard.exp_month + '/' + infoCard.exp_year.toString().slice(2, 4)}`
                  "
                  type="tel"
                  maxlength="5"
                  pattern="^[0-9/]*$"
                  @paste="onPaste"
                  @keypress="onlyNumberExpMonth"
                  @ionInput="onlyNumberExpMonth"
                  @ionChange="onlyNumberExpMonth"
                >
                </ion-input>
              </ion-item>
              <ion-text v-if="errors.expMonth || !isValidMonth" color="danger">
                <div class="pr-1 py-1">
                  {{ !isValidMonth ? $t('add_card_failed.expired_date_invalid') : $t(`${errors.expMonth}`) }}
                </div>
              </ion-text>
            </ion-col>

            <ion-col>
              <ion-item :class="['fs-3 custom-item', 'item-' + getColorValidate('cvv', errors.cvv)]">
                <ion-label :color="getColorValidate('cvv', errors.cvv)" position="stacked">
                  {{ $t('accountPage.cvc_cvv') }}
                </ion-label>
                <ion-input
                  v-model="card.cvv"
                  :value="card.cvv"
                  name="cvv"
                  :placeholder="!isEdit ? `123` : '***'"
                  type="tel"
                  pattern="^[0-9]*$"
                  maxlength="3"
                  @paste="onPaste"
                  @keypress="onlyNumberCvv"
                  @ionInput="onlyNumberCvv"
                  @ionChange="onlyNumberCvv"
                >
                </ion-input>
              </ion-item>
              <ion-text v-if="errors.cvv" color="danger">
                <div class="pr-1 py-1">{{ $t(`${errors.cvv}`) }}</div>
              </ion-text>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="ion-no-padding d-flex justify-end align-center my-2">
              <ion-label
                class="mr-1 font-style-toolbar-button-regular text-gray-600"
                v-if="listNetsCard.length > 0"
                >{{ $t('unable_set_as_default') }}</ion-label
              >
              <ion-label
                class="mr-1 font-style-toolbar-button-regular text-gray-600"
                v-if="!listNetsCard.length"
                >{{ $t('accountPage.set_as_default') }}</ion-label
              >
              <div class="d-flex justify-center align-center mr-2" @click="handleOpenAlert">
                <ion-text>?</ion-text>
              </div>
              <ion-toggle
                mode="ios"
                @ionChange="handleChangeToggle($event.detail.checked)"
                :checked="listNetsCard.length > 0 ? false : isDefault"
                :disabled="listNetsCard.length > 0"
              >
              </ion-toggle>
            </ion-col>
          </ion-row>
        </ion-grid>
      </vee-form>
    </ion-content>
    <ion-footer class="ion-no-border">
      <div class="d-flex flex-column">
        <ion-button
          class="btn-save font-style-button-default"
          @click="!isEdit ? saveCard() : handleEditCard()"
        >
          {{ !isEdit ? $t('accountPage.add_card') : $t('update_changes') }}
        </ion-button>
        <ion-button
          v-if="isEdit"
          class="btn-save font-style-button-default"
          shape="round"
          fill="outline"
          @click="handleDeleteCard"
        >
          {{ $t('delete') }}
        </ion-button>
      </div>
    </ion-footer>
  </ion-page>
</template>

<script>
import { isCreditCardNumber, isNumeric } from '@/modules/b2b/services/libs/helper';
import { stripe } from '@/modules/b2b/services/libs/payment';
import { presentAlert } from '@/modules/shared/utils/';
import { close, closeOutline } from 'ionicons/icons';
import { Form as VeeForm, useField, useForm } from 'vee-validate';
import { defineComponent, onMounted, ref } from 'vue';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('b2b/payment');
export default defineComponent({
  name: 'ModalAddCard',
  components: {
    VeeForm
  },
  data() {
    return {
      cardBrand: '',
      firstChange: {
        cardNumber: false,
        expMonth: false,
        cvv: false
      },
      isValidMonth: true
    };
  },
  setup(props, ctx) {
    // Define a validation schema
    const cardSchema = {
      cardNumber: (v) =>
        !v
          ? 'accountPage.cannot_be_empty'
          : !isCreditCardNumber(v)
          ? 'add_card_failed.card_number_invalid'
          : true,
      expMonth: (v) =>
        !v
          ? 'accountPage.cannot_be_empty'
          : !isNumeric(v.slice(0, 2)) ||
            v < 1 ||
            v > 12 ||
            ('' + v).length < 2 ||
            !isNumeric(v.slice(3, 5)) ||
            v.slice(3, 5).length < 2 ||
            v.slice(2, 3) !== '/'
          ? 'add_card_failed.expired_date_invalid'
          : true,
      cvv: (v) =>
        !v
          ? 'accountPage.cannot_be_empty'
          : !isNumeric(v) || ('' + v).length !== 3
          ? 'add_card_failed.cvc_invalid'
          : true
    };
    const initialValues = {
      cardNumber: '',
      expMonth: '',
      cvv: ''
    };
    const initialErrors = {
      cardNumber: '',
      expMonth: '',
      cvv: ''
    };
    const isDefault = ref(false);
    // Create a form context with the validation schema
    const { errors, values: card, validate } = useForm({
      validationSchema: cardSchema,
      initialValues,
      initialErrors
    });
    const { value: cardNumber } = useField('cardNumber');
    const { value: expMonth } = useField('expMonth');
    const { value: cvv } = useField('cvv');
    const handleChangeToggle = (e) => {
      isDefault.value = e;
    };
    const handleOpenAlert = async () => {
      if (props.isNetCard) {
        await presentAlert({
          mode: 'ios',
          message:
            'You already have NETS Card in this device. If you want to set Credit/Debit Card as default payment, you have to remove existing NETS Card first.'
        });
      }
    };
    const handleDeleteCard = () => {
      ctx.emit('remove-card', props.infoCard.id);
    };
    const handleEditCard = () => {
      ctx.emit('edit-card', props.infoCard.id, isDefault.value);
    };
    onMounted(() => {
      if (props.isDefaultCard && !props.isNetCard) {
        isDefault.value = true;
      }
    });
    return {
      close,
      errors,
      cardSchema,
      card,
      cardNumber,
      expMonth,
      cvv,
      validate,
      closeOutline,
      isDefault,
      handleChangeToggle,
      handleOpenAlert,
      handleDeleteCard,
      handleEditCard
    };
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    infoCard: {
      type: Object,
      default: null
    },
    isNetCard: {
      type: Boolean,
      default: false
    },
    isDefaultCard: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (this.isNetCard) {
      this.isDefault = false;
    }
  },
  computed: {
    ...mapGetters(['listNetsCard'])
  },
  emits: ['add-card', 'close-modal'],
  methods: {
    async saveCard() {
      // set first change is true
      for (const key in this.firstChange) {
        this.firstChange[key] = true;
      }
      // validate form
      const { valid } = await this.validate();
      if (valid && this.isValidMonth) {
        const card = {
          cardNumber: this.cardNumber.replace(/\s+/g, ''),
          expMonth: Number(this.expMonth.slice(0, 2)),
          expYear: Number(this.expMonth.slice(3, 5)),
          cvc: this.cvv
        };
        this.$emit('add-card', card, this.isDefault);
      }
    },
    cardImg(brand) {
      return stripe.getCardImage(brand);
    },
    onCardNumberChange(event) {
      this.onlyNumber(event);
      if (this.cardNumber) {
        this.cardNumber = this.cardNumber
          .replace(/\s+/g, '')
          .replace(/[^0-9]/gi, '')
          .replace(/(\d{4})/g, '$1 ')
          .trim();
        this.cardBrand = stripe.detectCardType(this.cardNumber);
      }
    },
    onlyNumber(event) {
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
      this.firstChange[event.target.name] = true;
    },
    onlyNumberExpMonth(event) {
      const isNumberOrSlash = /^[0-9/]*$/.test(event.target.value);

      // If the input is not a number or a slash, remove the non-numeric characters
      if (!isNumberOrSlash) {
        event.target.value = event.target.value.replace(/[^0-9/]/g, '');
      }
      this.card.expMonth = event.target.value;
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if ((keyCode < 48 || keyCode > 57) && !event.code.startsWith('Digit')) {
        event.preventDefault();
      }
    },
    onlyNumberCvv(event) {
      const isNumber = /^[0-9]*$/.test(event.target.value);

      // If the input is not a number or a slash, remove the non-numeric characters
      if (!isNumber) {
        event.target.value = event.target.value.replace(/[^0-9]/g, '');
      }
      this.card.cvv = event.target.value;
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if ((keyCode < 48 || keyCode > 57) && !event.code.startsWith('Digit')) {
        event.preventDefault();
      }
    },
    onPaste(event) {
      const clipboardData = event.clipboardData || event.originalEvent.clipboardData || window.clipboardData;
      let pastedData = clipboardData.getData('text');
      pastedData = pastedData.replace(/\s+/g, '');
      if (isNaN(pastedData)) {
        event.preventDefault();
      }
    },
    getColorValidate(name, errMsg) {
      return errMsg ? 'danger' : 'primary';
    },
    closeModal() {
      this.$emit('close-modal');
    }
  },
  watch: {
    'card.expMonth'() {
      if (this.card.expMonth.charAt(0) === '/' || this.card.expMonth.slice(0, 1) === '/') {
        this.card.expMonth = this.card.expMonth.substring(1);
      }
      let checkMonth = this.card.expMonth.slice(0, 2);
      let checkYear = this.card.expMonth.slice(3, 5);
      this.isValidMonth = checkMonth > 12 || checkYear < 23 ? false : true;

      // set "/"
      if (this.card.expMonth.length === 2) {
        this.card.expMonth = this.card.expMonth + '/';
      }
    }
  }
});
</script>

<style lang="scss" scoped>
.custom-header.header-md::after {
  background-image: none;
}
.custom-toolbar {
  padding: 8px 16px;
  --border-width: 0px !important;
}
.btn-save::part(native) {
  border-radius: 10px;
}
.box-image {
  position: absolute;
  right: 0;
}
.box-image {
  position: absolute;
  right: 0;
}
.card-image {
  margin-top: 3px;
  width: 32px;
  height: auto;
}
.line {
  font-weight: 300;
  color: #888888;
  font-size: 25px;
  display: inline-block;
  margin-left: 8px;
  margin-right: 16px;
  margin-bottom: 20px;
  text-align: center;
}
.custom-item::part(native) {
  padding: 0px;
  --inner-padding-end: 0px;
}
.item-danger {
  --border-color: var(--ion-color-danger);
}
.item-primary {
  --border-color: var(--ion-color-primary);
}
ion-grid {
  ion-row:nth-child(3) {
    & > ion-col {
      & > div {
        width: 21px;
        height: 21px;
        border: 2px solid #eb8c31;
        border-radius: 20px;
        & > ion-text {
          border-radius: 50px;
          color: #eb8c31;
        }
      }
    }
  }
}
ion-footer {
  & > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    & > ion-button {
      height: 45px;
      width: 100%;
      text-transform: none;
      margin: 10px 0px;
      padding: 0px 15px;
    }
    & > ion-button:nth-child(2) {
      --border-color: none;
      --border-width: 0px !important;
      color: red;
    }
  }
}
</style>
