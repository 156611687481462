<template>
  <div class="px-4" v-if="isFinanceModuleEnabled">
    <ion-card class="mx-0 mt-4 px-2 py-2 shadow">
      <ion-grid>
        <ion-row>
          <ion-label class="card-label">{{ $t('my_invoices') }} </ion-label>
        </ion-row>
        <ion-row>
          <ion-col size="6">
            <ion-row class="ion-align-items-center">
              <div class="box">
                <ion-icon class="i-help" :icon="helpCircleOutline"></ion-icon>
                <ion-text>{{ $t('available_credits') }}</ion-text>
              </div>
            </ion-row>
            <ion-row class="ion-justify-content-center">
              <ion-text class="vertical-space f-bold f-green">{{
                priceFormatter(currencySymbol, availableCreditAmount)
              }}</ion-text>
            </ion-row>
          </ion-col>
          <ion-col :size="6">
            <ion-row class="ion-align-items-center">
              <div class="box">
                <ion-icon color="danger" class="f-icon" :icon="alertCircleOutline"></ion-icon>
                <ion-text color="danger">{{
                  $t('unpaid_invoices', { unpaidInvoiceAmount: totalUnpaidInvoices })
                }}</ion-text>
              </div>
            </ion-row>
            <ion-row class="ion-justify-content-center">
              <ion-text color="dark" class="vertical-space f-bold">{{
                priceFormatter(currencySymbol, unpaidAmount)
              }}</ion-text>
            </ion-row>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-button expand="block" :color="hasInvoiceUnpaid ? 'danger' : 'primary'" @click="enterPayment">
            <ion-label>{{ hasInvoiceUnpaid ? $t('make_payment') : $t('wallet.view_all') }}</ion-label>
          </ion-button>
        </ion-row>
      </ion-grid>
    </ion-card>
  </div>
</template>
<script>
import { ACTIONS } from '@/modules/b2b/store/payment/actions';
import Clevertap from '@/services/shared/helper/clevertap';
import { priceFormatter } from '@/utils/';
import { alertCircleOutline, helpCircleOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('b2b/payment');

export default defineComponent({
  name: 'CardInvoiceAccount',
  inject: ['$storage'],
  setup() {
    const router = useRouter();
    const enterPayment = () => {
      Clevertap.recordEventWithName('Invoices Viewed from Account Screen');
      router.push(`/b2b/invoices/select-invoices`);
    };
    return {
      enterPayment,
      alertCircleOutline,
      helpCircleOutline,
      priceFormatter,
      user: ref(null),
      company: ref(null)
    };
  },
  computed: {
    ...mapGetters(['xeroCustomer', 'totalUnpaidInvoices', 'status', 'isFinanceModuleEnabled']),
    currencySymbol() {
      return this.user && this.user.country.currency_symbol;
    },
    availableCreditAmount() {
      const creditAmount =
        Number(this.xeroCustomer?.overpayment_amount || 0) +
        Number(this.xeroCustomer?.credit_notes_amount || 0);
      if (creditAmount < 0) {
        return 0;
      } else {
        return creditAmount;
      }
    },
    unpaidAmount() {
      return Number(this.xeroCustomer?.outstanding_amount || 0);
    },
    hasInvoiceUnpaid() {
      return this.unpaidAmount > 0;
    }
  },
  async mounted() {
    this.user = await this.$storage.getUser();
    this.company = await this.$storage.getSelectedCompany();
    await this.checkIsFinanceModuleEnabled();
    if (!this.isFinanceModuleEnabled) return;
    this.getXeroCustomer();
    this.getTotalUnpaidInvoices();
  },
  methods: {
    ...mapActions([
      ACTIONS.GET_XERO_CUSTOMER,
      ACTIONS.GET_TOTAL_UNPAID_INVOICES,
      ACTIONS.CHECK_IS_FINANCE_MODULE_ENABLED
    ]),
    async checkIsFinanceModuleEnabled() {
      await this[ACTIONS.CHECK_IS_FINANCE_MODULE_ENABLED]({
        tenantId: this.user.tenant.id,
        customerId: this.company.id
      });
    },
    async getXeroCustomer() {
      await this[ACTIONS.GET_XERO_CUSTOMER]({
        tenantId: this.user.tenant.id,
        customerId: this.company.id
      });
    },
    async getTotalUnpaidInvoices() {
      await this[ACTIONS.GET_TOTAL_UNPAID_INVOICES]({
        tenantId: this.user.tenant.id,
        customerId: this.company.id
      });
    }
  }
});
</script>
<style lang="scss" scoped>
.card-label {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.shadow {
  box-shadow: 0 0 3px 1px #f0f0f0;
}
ion-button {
  text-transform: capitalize;
  margin-left: -1px;
  width: 100%;
}
.f-icon {
  font-size: 1.5rem;
}
ion-icon {
  margin-right: 0.2rem;
}
.i-help {
  color: #eb8c31;
  font-size: 1.5rem;
}
.box {
  display: flex;
  align-items: center;
  ion-label {
    margin-left: 5px;
  }
}
ion-text {
  font-size: 13px;
}
.f-bold {
  font-weight: bold;
  font-size: 16px;
}
.f-green {
  color: #039855;
}
.vertical-space {
  padding: 0.5rem;
}
</style>
