<template>
  <div class="form-language">
    <div class="title">{{ $t('select_preferred_language') }}</div>
    <div class="language-select">
      <ion-list>
        <ion-radio-group :value="language" mode="ios">
          <ion-item
            v-for="(data, index) in defaultLanguages"
            @click="handleUpdateLanguage(data.key)"
            :key="index"
          >
            <ion-label>{{ data.value }}</ion-label>
            <ion-radio slot="end" :value="data.key"></ion-radio>
          </ion-item>
        </ion-radio-group>
      </ion-list>
    </div>
  </div>
</template>

<script>
import { defaultLanguages } from '@/services/shared/helper/countries';
import { apolloClient } from '@/main';
import { updateLanguage } from '@/services/shared/graphql';
import { useI18n } from 'vue-i18n';
import CleverTap from '@/services/shared/helper/clevertap.js';

export default {
  inject: ['$storage'],
  setup() {
    const { locale } = useI18n();

    return {
      locale,
      defaultLanguages
    };
  },
  data() {
    return {
      language: ''
    };
  },
  async mounted() {
    this.language = await this.$storage.get('language');
  },
  methods: {
    async handleUpdateLanguage(language) {
      this.locale = language;
      const { data } = await apolloClient.mutate({
        mutation: updateLanguage,
        variables: {
          language
        }
      });
      await this.$storage.set('language', data.updateLanguage.language);
      CleverTap.updateUserLanguage(language);
      this.$emit('off-modal');
    }
  }
};
</script>

<style src="./styles.scss" lang="scss" scoped></style>
